import React, { useEffect, useState } from 'react';
import Button from './../components/Button';
import css from './copy.module.css';

// https://stackoverflow.com/questions/39501289/in-reactjs-how-to-copy-text-to-clipboard
// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript

const sections = [
  ['А́', 'Е́', 'Є́', 'И́', 'І́', 'Ї́', 'О́', 'У́', 'Ю́', 'Я́'],
  ['а́', 'е́', 'є́', 'и́', 'і́', 'ї́', 'о́', 'у́', 'ю́', 'я́'],
  ['—', 'э', 'ы', '«', '»']
];

const buildButtons = ({ onClick, values }) => {
  let i = 0;
  return values.map(value => <Button key={i++} onClick={onClick} value={value} />);
};

const buildSection = ({ i, onClick, values }) => {
  if (!document.queryCommandSupported('copy')) return null;
  return (
    <div className={css.section} key={i}>
      { buildButtons({ onClick, values }) }
    </div>
  );
};

const buildSections = ({ onClick, sections }) => {
  let i = 0;
  return sections.map((section) => {
    i++;
    return buildSection({ i, onClick, values: section });
  });
};

const Copy = () => {
  const [state, setState] = useState({ copied: false, target: null });

  useEffect(() => {
    let timer;
    if (state.copied) {
      timer = setTimeout(() => { setState({ copied: false, target: null }); }, 2000);
    }
    return () => {
      clearTimeout(timer);
      if (state.target) {
        state.target.classList.remove(css.copied);
      }
    };
  });

  const onClick = (e) => {
    e.target.classList.add(css.copied);
    var textField = document.createElement('textarea');
    textField.innerText = e.target.innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setState({ copied: true, target: e.target });
  };

  return (
    <>
      <h1>Copy things</h1>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        { buildSections({ onClick, sections }) }
      </div>
    </>
  );
};

export default Copy;
