import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import PetresqueintLogo from '../img/petrescueint.jpg';
import MadeInUkraineLogo from '../img/made-in-ukraine.svg';
import MonstrovLogo from '../img/monstrov.webp';
import { ReactComponent as ProtezLogo } from '../img/protezfoundation.svg';
import UAHostingLogo from '../img/uahosting.webp';
import styles from './layout.module.css';

const Layout = () => {
    return (
      <>
        <section className={styles['flex-body']}>
          <ul>
              <li>
                  <Link to="/" >Home</Link>
              </li>
              <li>
                  <Link to="/copy">Copy things</Link>
              </li>
              {/* <li>
                  <Link to="/js">JavaScript</Link>
              </li>
              <li>
                  <Link to="/liberation" >Liberation Movement!</Link>
              </li>
              <li>
                  <Link to="/music">Music</Link>
                </li> */}
              <li>
                  <Link to="/pubkey">Public key</Link>
              </li>
              <li>
                  <Link to="/radio">Radio</Link>
              </li>
              <li>
                  <Link to="/wisdomry">Wisdomry</Link>
              </li>
          </ul>
          <div className={styles.outlet}>
            <Outlet />
          </div>
        </section>

        <hr />

        <section id="footer">
          <div>
            <Link to="https://petrescueint.org" reloadDocument>
              <img
                alt="Pet Rescue International"
                src={PetresqueintLogo}
                title="Pet Rescue International"
              />
            </Link>
          </div>
          <div>
            <Link to="https://stand-with-ukraine.pp.ua" reloadDocument>
              <img
                alt="Stand With Ukraine"
                src={MadeInUkraineLogo}
                title="Stand With Ukraine"
              />
            </Link>
          </div>
          <div>
            <Link to="https://uahosting.com.ua/billing/pl.php?15416" reloadDocument>
              <img
                alt="UAhosting"
                src={UAHostingLogo}
                title="UAhosting"
              />
            </Link>
          </div>
          <div>
            <Link to="https://monstrov.org" reloadDocument>
              <img
                alt="Корпорація монстрів"
                src={MonstrovLogo}
                title="Корпорація монстрів"
              />
            </Link>
          </div>
          <div title="Protez Foundation">
            <Link to="https://www.protezfoundation.org" reloadDocument>
              <ProtezLogo
                alt="Protez Foundation"
                className={styles.protez}
                style={{ width: '96px' }}
              />
            </Link>
          </div>
        </section>
      </>
    )
  };

  export default Layout;
