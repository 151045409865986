import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Copy from "./pages/copy";
import Home from "./pages/home";
import Js from "./pages/js";
import Layout from "./pages/layout";
import Liberation from "./pages/liberation";
import Music from "./pages/music";
import NoPage from "./pages/nopage";
import Pubkey from "./pages/pubkey";
import Radio from "./pages/radio";
import Wisdomry from "./pages/wisdomry";
// import mainLogo from './img/pushok.jpg';

import './App.css';

// type ButtonProps = {
//   onClick: () => void;
//   text: string;
// }

// type Rule = {
//   wrong: string;
//   right: string;
// }

// function Button(props: ButtonProps) {
//   const { text, onClick } = props;
//   return (
//     <button onClick={ onClick }>{ text }</button>
//   );
// }

// const getRules = async (): Promise<Rule[]> => {
//   try {
//     const myHeaders = new Headers();
//     myHeaders.append('apikey', 'temp123');
//     const requestOptions: RequestInit = {
//       method: 'GET',
//       // Don't supply headers to make CORS work
//       // headers: myHeaders,
//       redirect: 'follow',
//     };
//     const responseObj: Response = await fetch('https://pereklady.pp.ua/rules/test.json', requestOptions);
//     if (!responseObj.ok) {
//       throw new Error('Cannot fetch rules');
//     }
//     const rules: Rule[] = await responseObj.json();
//     return rules;
//   } catch (err) {
//     console.error('Something went wrong when fetching rules');
//     console.error(err);
//   }
//   return [];
// };

// const constructHtml = (rules: Rule[]): string => {
//   const items = rules.map(({ wrong, right }) => {
//     const cardHtml = `
//       <li>
//         <div class="card">
//           <span>${ wrong }</span>
//           <span>${ right }</span>
//         </div>
//       </li>
//     `;
//     return cardHtml;
//   });
//   return `<ul>${ items.join('\n') }</ul>`;
// };

// const updateOutput = async (id: string) => {
//   const rules = await getRules();
//   const html = constructHtml(rules);
//   const destElem = document.querySelector(`#${id}`);
//   if (destElem && html) {
//     destElem.innerHTML = html;
//   }
// };

//      <header className="App-header">
//        <img src={mainLogo} className="App-logo" alt="logo" />
//      </header>
//        <Button onClick={ () => updateOutput('rules') } text="Get the rules" />
//        <pre id="rules"></pre>



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/copy" element={<Layout />}>
            <Route index element={<Copy />} />
          </Route>
          <Route path="/js" element={<Layout />}>
            <Route index element={<Js />} />
          </Route>
          <Route path="/liberation" element={<Layout />}>
            <Route index element={<Liberation />} />
          </Route>
          <Route path="/music" element={<Layout />}>
            <Route index element={<Music />} />
          </Route>
          <Route path="/pubkey" element={<Layout />}>
            <Route index element={<Pubkey />} />
          </Route>
          <Route path="/radio" element={<Layout />}>
            <Route index element={<Radio />} />
          </Route>
          <Route path="/wisdomry" element={<Layout />}>
            <Route index element={<Wisdomry />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
